
.App{
  width: 100%;
  height: 100vh;
  background-color: #1d192b;
}

.ctn{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.logo{
  width: 100%;
  max-width: 600px;
}

.subtitle{
  color: white;
  font-size: 25px;
}